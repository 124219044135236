
import { Power0 } from 'gsap';

import pageMixin from '@/mixins/page';
import hasScrollTriggers from '@/mixins/hasScrollTriggers';

export default {
    mixins: [pageMixin, hasScrollTriggers],
    data() {
        return {
            headerSequenceDone: false,
            headerVideoPlaying: false,
            logoToTop: false,
            showScrollButton: false,
        };
    },
    // non-reactive data
    moduleTriggers: [],
    head() {
        return {};
    },
    computed: {
        pageData() {
            return this.$store.state.siteData.homepage;
        },
        preloaderOutroDone() {
            return this.$store.state.preloader.outroDone;
        },
        modules() {
            return this.pageData.modules.map((module, index) => {
                return {
                    id: `module-${index}`,
                    ...module,
                };
            });
        },
        vw() {
            return this.$store.state.viewport.width;
        },
        scrollButtonClasses() {
            if (!this.showScrollButton) {
                return 'opacity-0 translate-y-32';
            }
            return null;
        },
    },
    watch: {
        preloaderOutroDone(value) {
            if (value) {
                this.setupModuleTriggers();
                // this.setupSubscribeTrigger();
            }
        },
    },
    mounted() {
        // make sure to always scroll to top first so the header will be visible.
        window.scrollTo(0, 0);
    },
    methods: {
        setupScrollTriggers() {
            this.setupHeaderFade();
            this.setupArrowHide();
        },
        /**
         * When the modules below the header move into the viewport,
         * gradually fade out the header image.
         */
        setupHeaderFade() {
            const headerImage = this.$refs.header.$refs.bg;

            const tl = this.$gsap.timeline({
                scrollTrigger: {
                    scroller: this.scrollingElement,
                    trigger: this.$refs.modules,
                    start: 'top bottom',
                    end: 'top top',
                    scrub: true,
                },
            });
            tl.to(
                headerImage,
                {
                    opacity: 0.2,
                    ease: Power0.easeNone,
                },
                0
            );

            this.addScrollTrigger(tl);
        },
        /**
         * The arrow indicating to scroll down will fade out when scrolling down.
         */
        setupArrowHide() {
            const tl = this.$gsap.timeline({
                scrollTrigger: {
                    scroller: this.scrollingElement,
                    trigger: this.$refs.modules,
                    start: 'top bottom-=20',
                    toggleActions: 'restart none none reverse',
                },
            });
            tl.to(this.$refs.header.$refs.scrollIndicator.$el, {
                opacity: 0,
                ease: 'e1',
            });

            this.addScrollTrigger(tl);
        },
        /**
         * When the last module hits the top of the viewport
         * the module will pin so the subscribe module will move over it.
         */
        setupSubscribeTrigger() {
            const lastModule = this.$refs.modules.querySelector('.module:last-child');

            this.$ScrollTrigger.create({
                id: 'subscribe',
                scroller: this.scrollingElement,
                trigger: lastModule,
                pin: lastModule,
                pinSpacing: false,
                start: 'top top',
            });
        },
        /**
         * Each module can define its own scrolltrigger.
         */
        setupModuleTriggers() {
            const modules = this.$gsap.utils.toArray('.modules .has-trigger');

            modules.forEach((el) => {
                const moduleData = this.modules.find((module) => module.id === el.id);

                // get trigger vars defined in data file
                const triggerVars = moduleData.trigger;

                if (triggerVars) {
                    // combine trigger vars from data file with base trigger config + specified module trigger
                    const triggerConfig = {
                        scroller: this.scrollingElement,
                        trigger: el,
                        invalidateOnRefresh: true,
                        // markers: true,
                        ...triggerVars,
                        ...this.getModuleTrigger(triggerVars.id),
                    };

                    const trigger = this.$ScrollTrigger.create(triggerConfig);
                    this.$options.moduleTriggers.push(trigger);
                }
            });
        },
        getModuleTrigger(triggerId) {
            switch (triggerId) {
                case 'show-scroll-btn':
                    return this.getShowScrollButton();
                case 'hide-scroll-btn':
                    return this.getHideScrollButton();
                case 'move-logo':
                    return this.getLogoTrigger();
            }

            return null;
        },
        getShowScrollButton() {
            return {
                onEnter: () => {
                    this.showScrollButton = true;
                },
                onLeaveBack: () => {
                    this.showScrollButton = false;
                },
            };
        },
        getHideScrollButton() {
            return {
                onEnter: () => {
                    this.showScrollButton = false;
                },
                onLeaveBack: () => {
                    this.showScrollButton = true;
                },
            };
        },
        getLogoTrigger() {
            return {
                onEnter: () => {
                    this.logoToTop = true;
                },
                onLeaveBack: () => {
                    this.logoToTop = false;
                },
            };
        },
        onScrollDownButtonClick() {
            this.$gsap.to(window, {
                duration: 2,
                ease: 'e1',
                scrollTo: { y: `#${this.pageData.scrollDownButton.id}` },
            });
        },
        playIntro() {
            const tl = this.$gsap.timeline();
            tl.add(this.$refs.header.getIntro());
        },
    },
};
