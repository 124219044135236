import { render, staticRenderFns } from "./HomepageHeader.vue?vue&type=template&id=60eb48b8&"
import script from "./HomepageHeader.vue?vue&type=script&lang=js&"
export * from "./HomepageHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageSequence: require('/workspace/components/molecules/ImageSequence.vue').default,BaseVideo: require('/workspace/components/atoms/BaseVideo.vue').default,BasePicture: require('/workspace/components/atoms/BasePicture.vue').default,ScrollIndicator: require('/workspace/components/atoms/ScrollIndicator.vue').default})
