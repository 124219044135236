
import { array, integer } from 'vue-types';

export default {
    props: {
        images: array(),
        currentIndex: integer().def(0),
    },
    computed: {
        mappedImages() {
            return this.images.map((img) => {
                return {
                    src: img.mobile.src,
                    sources: [
                        {
                            media: '(orientation: portrait)',
                            src: img.mobile.src,
                        },
                        {
                            media: '(orientation: landscape)',
                            src: img.desktop.src,
                        },
                    ],
                };
            });
        },
    },
};
