
import { array, object } from 'vue-types';
import { SteppedEase, Power0 } from 'gsap';

export default {
    props: {
        imgSequence: array(),
        video: object(),
        image: object(),
    },
    data() {
        return {
            imgIndex: 0,
            sequenceDone: false,
        };
    },
    computed: {
        // sequenceLastFrame() {
        //   return this.imgIndex === this.imgSequence.length - 1;
        // },
    },
    watch: {
        /**
         * The last image in the sequence doesn't contain the logo.
         * This is the trigger for the homepage to start showing the svg logo over the image sequence.
         */
        // sequenceLastFrame(value) {
        //   if (value) {
        //     this.$emit('sequenceLastFrame');
        //   }
        // },
        sequenceDone(value) {
            if (value && this.$refs.video) {
                this.$emit('sequenceDone');

                this.$refs.video.play();
            } else if (value) {
                this.$emit('sequenceDone');
                this.$emit('videoPlaying');
            }
        },
    },
    methods: {
        getIntro() {
            const sequenceFrames = this.imgSequence.length;
            const fps = 6;
            const duration = sequenceFrames / fps;

            const tl = this.$gsap.timeline();
            tl.from(this.$refs.bg, { scale: 1.3, duration: 0.8, ease: 'e2' });

            // animate the image sequence
            tl.to(
                this,
                {
                    imgIndex: sequenceFrames - 1,
                    duration,
                    ease: SteppedEase.config(sequenceFrames - 1),
                    onComplete: () => {
                        this.sequenceDone = true;
                    },
                },
                0
            );

            tl.from(this.$refs.scrollIndicator.$el, { opacity: 0, duration: 0.6, ease: Power0.easeNone });

            return tl;
        },
        /**
         * Use video playback start as trigger to show the logo on the homepage
         */
        onVideoPlaying() {
            this.$emit('videoPlaying');
        },
    },
};
