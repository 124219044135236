import { mapState } from 'vuex';
// import { gsap, Power0 } from 'gsap';

// function resetScroll() {
//   console.log('== resetScroll ==');
//   console.log('  before:', window.pageYOffset);
//   window.scrollTo(0, 0);
//   console.log('  after:', window.pageYOffset);
// }

export default {
  computed: {
    ...mapState({
      preloaderoutroStarted: 'preloader/outroStarted',
    }),
  },
  beforeDestroy() {
    this.$nuxt.$off('pageIntro');
    // this.killSmoothScroller();
  },
  mounted() {
    this.$nextTick(() => {
      // when page loads and preloader was already done, also show intro
      if (this.preloaderoutroStarted) {
        this.playIntro();
      } else {
        this.$nuxt.$on('pageIntro', this.playIntro);
      }
    });
  },
  methods: {
    // setupSmoothScroll() {
    //   // smooth scrolling container
    //   this.scrollTween = this.$gsap.to(this.$el, {
    //     y: () => -(this.$el.clientHeight - document.documentElement.clientHeight),
    //     ease: 'none',
    //     scrollTrigger: {
    //       trigger: document.body,
    //       start: 'top top',
    //       end: 'bottom bottom',
    //       scrub: 0.75,
    //       invalidateOnRefresh: true,
    //       // markers: true,
    //     },
    //   });
    // },
    // killSmoothScroller() {
    //   if (this.scrollTween) {
    //     this.scrollTween.scrollTrigger?.kill();
    //     this.scrollTween.kill();
    //   }

    //   this.scrollTriggers = [];
    // },
    /**
     * By default: just render immediately.
     * Override to define a unique intro for each page template.
     */
    playIntro() {
      console.log('playIntro | Override and roll your own...');
    },
  },
};
