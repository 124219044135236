
import { array } from 'vue-types';

const SUPPORTED_COMPONENTS = {
    'single-img': () => import('@/components/organisms/SingleImageModule'),
    'image-row': () => import('@/components/organisms/ImageRowModule'),
    richText: () => import('@/components/organisms/RichTextModule'),
    subscribe: () => import('@/components/organisms/SubscribeModule'),
    product: () => import('@/components/organisms/ProductModule.vue'),
};

export default {
    /**
     * lazy load each component using dynamic import
     */
    components: SUPPORTED_COMPONENTS,
    props: {
        modules: array(),
    },
    methods: {
        getComponent(module) {
            if (!module) {
                return null;
            }

            if (!SUPPORTED_COMPONENTS[module.type]) {
                throw new Error(`Module type not supported: ${module.type}`);
            }

            return module.type;
        },
        getModuleClasses(module) {
            return [
                module.marginBottom === false ? 'mb-0' : null,
                module.padding ? 'py-fluid-8xl-11xl' : null,
                module.bgFill === true ? 'bg-white' : null,
                module.bgSplitFill === true ? 'bg-split' : null,
                module.trigger ? 'has-trigger' : null,
                module.front ? 'z-20' : null,
            ];
        },
    },
};
