import { render, staticRenderFns } from "./HomeTemplate.vue?vue&type=template&id=58ce1876&scoped=true&"
import script from "./HomeTemplate.vue?vue&type=script&lang=js&"
export * from "./HomeTemplate.vue?vue&type=script&lang=js&"
import style0 from "./HomeTemplate.vue?vue&type=style&index=0&id=58ce1876&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ce1876",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomepageHeader: require('/workspace/components/molecules/HomepageHeader.vue').default,SiteLogo: require('/workspace/components/atoms/SiteLogo.vue').default,ModulesList: require('/workspace/components/organisms/ModulesList.vue').default,ScrollDownButton: require('/workspace/components/molecules/ScrollDownButton.vue').default})
